import {
  invitation_1,
  invitation_2,
  invitation_3,
  restaurant_1,
  restaurant_2,
  restaurant_3,
} from "../../images/BirthDayImg";
export const invitation1 = [invitation_1, invitation_2, invitation_3];
export const restImg = [restaurant_1, restaurant_2, restaurant_3];
