import React from 'react'
import './InvitationLinkModal.css'

function InvitationLinkModal({link, code, setInvitation_link_modal}) {
  console.log(link, code);
  return (
    <div className='invitation_link_modal'>
       {code && <p>promo code is: {code}</p>}
       {link && <p>link for invite: {link}</p>}
        <span className='invitaionModalXmark' onClick={() => setInvitation_link_modal(false)}>X</span>
    </div>
  )
}

export default InvitationLinkModal