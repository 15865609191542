import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ModalPrivate from '../components/ModalPrivate/ModalPrivate';
import { modalPrivateSelector } from '../store/slices/ModalPrivate/ModalPrivateSlice';
import { useSelector } from 'react-redux';
import LanguageModaalTiket from '../components/Tikets/TicetsCustom/LanguageModalTiket/LanguageModaalTiket';
import { tiketsLengModalSelector } from '../store/slices/TiketsLengModal/TiketsLengModalSlice';
import InvitationLinkModal from '../components/InvitationLinkModal/InvitationLinkModal';
import { customBasesUrlFunc } from '../utils/helperFunck';

function HomeWraper() {
const modalPrivateProject = useSelector(modalPrivateSelector)
const tiketsLengModal = useSelector(tiketsLengModalSelector)
const [invitation_link_modal, setInvitation_link_modal] = useState(false);
const params = customBasesUrlFunc();

  useEffect(() => {
    params?.event_url && setInvitation_link_modal(true);
    console.log(params?.event_url,66666);
  }, [])

  
  return (
    <div className="home_wraper">
      <Outlet />
      {modalPrivateProject && <ModalPrivate/>}
      {tiketsLengModal && <LanguageModaalTiket/>}
      {invitation_link_modal && <InvitationLinkModal link={params.event_url + '&token=' +params.token + (params.p_token ? '&p_token=' + params.p_token : '') } code={params.promo_code} setInvitation_link_modal={setInvitation_link_modal}/>}
   </div>
  );

}

export default HomeWraper;
