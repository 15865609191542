import React from 'react'
import './LoaderProj.css'

function LoaderProj() {
  return (
    <div className='loader_block'>
        <span class="loader"></span>
    </div>
  )
}

export default LoaderProj