import { imageChurch, imageCouple, imageRestaurant } from "../images/images";

export const audioData = [
  {
    id: "1",
    src: "Christina Perri feat. Steve Kazee - A Thousand Years (Part 2).mp3",
    track: "Christina Perri feat. Steve Kazee - A Thousand Years (Part 2)",
  },
  {
    id: "2",
    src: "a-gentle-breeze-189206.mp3",
    track: "A gentle breeze",
  },
  {
    id: "3",
    src: "my-love_short-177579.mp3",
    track: "My love",
  },
];

export const sectiosData = {
  template_id: 1,
  language: "am",
  date: "2024-09-08",
  sound_path: "",
  logo_path: "",
  invitation_name: "",
  age: "",
  sections: [
    {
      section_name: "wedding",
      section_number: "1",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: "content textx text",
      time: "11:30",
      address: "Baghramyn 1",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageCouple,
        },
      ],
    },

    {
      section_name: "wedding",
      section_number: "2",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: "Pharaon Complex (Cottage N2)",
      time: "13:00",
      address: "Baghramyn 1",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageCouple,
        },
      ],
    },

    {
      section_name: "wedding",
      section_number: "3",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: "Церковь Святой Марии Богородицы",
      time: "15:15",
      address: "г. Ереван, Арменакяна 225",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageChurch,
        },
      ],
    },

    {
      section_name: "wedding",
      section_number: "4",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: "Ресторан Florence",
      time: "17:00",
      address: "г. Ереван. Барбюса 64/2",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageRestaurant,
        },
      ],
    },

    {
      section_name: "wedding",
      section_number: "5",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: "Ресторан Florence",
      time: "19:00",
      address: "г. Ереван. Барбюса 64/2",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageRestaurant,
        },
      ],
    },
  ],
};

//  ====================================tarifs====================================
